import axios, { InternalAxiosRequestConfig } from 'axios';
import { AuthEnum } from '../enum/auth.enum';
import { Auth } from '../models/auth.model';
import { useNotificationService } from '../services/notification.service';
import { clear, getLocalStorageItem } from '../util/store';

const http = axios.create({
  baseURL: process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API,
});

const token = () => {
  const auth = getLocalStorageItem(AuthEnum.TOKEN_KEY) as Auth;
  return auth?.access_token;
};

const defaultErrorMessage = 'Ação não concluída. Tente novamente ou contate o suporte! [2]';

http.interceptors.request.use(
  (request: InternalAxiosRequestConfig<any>) => {
    if (!request.headers['Authorization'])
      request.headers['Authorization'] = `Bearer ${token()}`;

    if (!request.headers['Content-Type'])
      request.headers['Content-Type'] = 'application/json';

    if (!request.headers['Accept'])
      request.headers['Accept'] = 'application/json';

    return request;
  },
  (error) => Promise.reject(error),
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response) {
      const { status } = error.response;
      const notification = useNotificationService();

      switch (status) {
        case 401: {
          if (error.config.headers['MensagemPersonalizada']) {
            notification({
              description: error.config.headers['MensagemPersonalizada'],
              type: 'info',
              message: 'Mensagem',
            });
          } else {
            notification({
              description: 'Usuário ou senha incorreto',
              type: 'warning',
              message: 'Falha ao Logar',
            });
          }
          const path = window.location.pathname;

          if (path !== '/login') window.location.href = '/login';

          clear();
          break;
        }
        case 404:
          notification({
            description: error.response.data?.message,
            type: 'warning',
            message: 'Aviso',
          });
          break;
        case 400:
          if (error.response.data.error_description === 'Bad credentials') {
            notification({
              description: 'Usuário ou senha incorreto',
              type: 'warning',
              message: 'Falha ao Logar',
            });
            break;
          }
          notification({
            description:
              error.response.data?.message || defaultErrorMessage,
            type: 'error',
            message: 'Atenção!',
          });
          break;

        default:
          notification({
            description:
              error.response.data?.message || defaultErrorMessage,
            type: 'error',
            message: 'Atenção!',
          });
          break;
      }
    }
    return Promise.reject(error);
  },
);

export default http;
